body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000;
  color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*
  Home app
*/
.home-container {
  padding: 30px;
  text-align: center;
}

/*
  Prayer Times App
*/
.prayer-times-container {
  /* padding: 30px; */
}

.prayer-times-container .list-group-item {
  padding: 0 25px !important;
  font-size: 19px;
}

.prayer-times-container .heading {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: bold;
}

.prayer-times-container .list-item-time {
  text-align: right;
}
